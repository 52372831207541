import Logo from "assets/image/logo_wika.jpg";
import MenuConfig from "./MenuConfig";
import RoutesConfig from "./RoutesConfig";

export default {
  LOGO: Logo, //Logo
  MODUL: "HRD User", // Konfigurasi nama modul pada sidebar
  MENU: MenuConfig, //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig, //Konfigurasi routing
};
